import React, { useState, useRef, useEffect } from 'react';
import MessageList from './MessageList';
import ChatInput from './ChatInput';
import { DrawerIcon } from '../../assets/images'
import { ApiCollection } from "../../config/envConfig";
import { startEventStream } from "../../utils/common/eventSource";
import { useAxios } from '../../hooks/useAxios';
import './chat.css';
import { useUser } from '../../redux/useStore';
import { useSearchParams } from 'react-router-dom';

interface Message {
  id: string;
  text: string;
  isBot: boolean;
  file?: {
    url: string;
    type: string;
    name: string;
  };
}

interface ChatContainerProps {
  onClose: () => void;
  sourcUrl: string;
  usage: 'course-help' | 'course-modify';
  onReplace?: () => Promise<void>;
  referenceData?: string;
}

const ChatContainer: React.FC<ChatContainerProps> = (props) => {
  const [messages, setMessages] = useState<Message[]>(() => {
    if (props.usage === 'course-modify') {
      return [{
        id: Date.now().toString(),
        text: "Do you want to change or update the module?",
        isBot: true
      }];
    }
    return [];
  });
  const [isTyping, setIsTyping] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [isLoading, setIsLoading] = useState(props.usage === 'course-help');
  const eventSourceRef = useRef<any | null>(null);
  const currentMessageIdRef = useRef<string | null>(null);
  const [showReplaceButton, setShowReplaceButton] = useState(false);

  const axios = useAxios();
  const user = useUser()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (props.usage === 'course-help') {
      loadChatHistory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadChatHistory = async () => {
    try {
      setIsLoading(true);

      const enrollmentId = searchParams.get("enrollmentId") ?? "";
      const moduleId = searchParams.get("moduleId") ?? "";

      const response = await axios.get(
        ApiCollection.chanakyaGuru.courseBotHistory(enrollmentId, moduleId)
      );

      if (response.data) {
        const formattedMessages = response.data.map((msg: any) => ({
          id: msg.id,
          text: msg.content,
          isBot: msg.role === "assistant"
        }));

        setMessages(formattedMessages);
      }
    } catch (error) {
      console.error("Error loading chat history:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
    }, 300);
  };

  const handleSendMessage = async (text: string, file?: File) => {

    // Check if file is attached
    let fileData;
    if (file) {
      const blobUrl = URL.createObjectURL(file);
      fileData = {
        url: blobUrl,
        type: file.type,
        name: file.name
      };
    }

    const userMessage: Message = {
      id: Date.now().toString(),
      text,
      isBot: false,
    };

    if (fileData) {
      userMessage.file = fileData;
    }

    setMessages(prev => [...prev, userMessage]);
    setIsTyping(true);

    // =========== CUSTOMIZE COURSE FUNCTIONALITY ===========
    if (props.usage === 'course-modify') {
      try {
        const eventSource = startEventStream(
          "json",
          props.sourcUrl,
          user?.token,
          JSON.stringify({ query: text }),
          onMessage,
          onError
        );

        if (eventSource) {
          eventSourceRef.current = eventSource;
        }
      } catch (error) {
        console.error('Error:', error);
        setIsTyping(false);
      }
    }
    // =========== COURSE MODULE HELP FUNCTIONALITY ===========
    else if (props.usage === 'course-help') {
      try {
        const enrollmentId = searchParams.get("enrollmentId") ?? "";
        const moduleId = searchParams.get("moduleId") ?? "";

        let body;

        if (file) {
          body = new FormData();
          body.append('query', text);
          body.append('enrollment_id', enrollmentId || '');
          body.append('module_id', moduleId || '');
          body.append('file', file);
        } else {

          // If reference data is available, send it in the request
          if (props.referenceData) {
            body = JSON.stringify({ query: text, enrollment_id: enrollmentId, module_id: moduleId, content: props.referenceData });
          }
          // Else, send the request without reference data
          else {
            body = JSON.stringify({ query: text, enrollment_id: enrollmentId, module_id: moduleId });
          }

        }


        const eventSource = startEventStream(
          file ? "formdata" : "json",
          props.sourcUrl,
          user?.token,
          body,
          onMessage,
          onError
        );

        if (eventSource) {
          eventSourceRef.current = eventSource;
        }
      } catch (error) {
        console.error('Error sending message:', error);
        setIsTyping(false);
      }
    }
  };


  const onMessage = (e: MessageEvent) => {
    if (e.data === "[DONE]") {
      currentMessageIdRef.current = null;
      onStopStreaming();

      if (props.usage === 'course-modify') {
        setMessages(prev => [...prev, {
          id: Date.now().toString(),
          text: `Do you really want to update with these changes?`,
          isBot: true
        }]);
        setShowReplaceButton(true);
      }
      return;
    }

    try {
      const payload = JSON.parse(e.data);

      if (payload?.choices && payload.choices.length > 0) {
        const botMessage = payload.choices[0].text;

        if (!currentMessageIdRef.current) {
          currentMessageIdRef.current = payload.id || Date.now().toString();
        }

        setMessages(prevMessages => {
          const newMessages = [...prevMessages];
          const messageId = currentMessageIdRef.current || Date.now().toString();
          const existingIndex = newMessages.findIndex(msg => msg.id === messageId);

          if (existingIndex === -1) {
            const newBotMessage: Message = {
              text: botMessage,
              isBot: true,
              id: messageId
            };
            return [...newMessages, newBotMessage];
          } else {
            newMessages[existingIndex].text =
              (newMessages[existingIndex].text || '') +
              (botMessage || '');
            return newMessages;
          }
        });
      }
    } catch (error) {
      console.error("Failed to parse message data:", error);
    }
  };

  const onError = (error: any) => {
    console.error("Chat Error:", error);
    setIsTyping(false);

    let errorText = "❌ There was an error processing your request. Please try again.";
    try {
      errorText = JSON.parse(error.data).detail;
    } catch {
      // Use default error message
    }

    setMessages(prev => [...prev, {
      id: Date.now().toString(),
      text: errorText,
      isBot: true
    }]);

    onStopStreaming();
  };

  const onStopStreaming = () => {
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
      eventSourceRef.current = null;
    }
    setIsTyping(false);
  };

  const handleReplace = async () => {
    setShowReplaceButton(false);
    setIsTyping(true);

    try {
      //  making the PUT API call with no payload
      const response = await axios.put(
        ApiCollection.chanakyaGuru.createCrashCourseChat
      );

      // If PUT is successful, refresh the data and close
      if (response.status === 200) {
        setIsTyping(false);

        if (props.onReplace) {
          await props.onReplace();  // fetchCrashCourse
        }

        if (props.onClose) {
          props.onClose();
        }
      }

    } catch (error) {
      console.error('Error:', error);
      setMessages(prev => [...prev, {
        id: Date.now().toString(),
        text: "Failed to update the course. Please try again.",
        isBot: true
      }]);
      setIsTyping(false);
    }
  };

  useEffect(() => {
    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
    };
  }, []);

  return (
    <div className={`coursebot-window ${isClosing ? 'closing' : ''}`}>
      <div className="coursebot-header">
        <div className="coursebot-title">
          {/* <img src={LearnIcons.AskBot} alt="easier" className="bot-icon" /> */}
          Ask Chanakya !
        </div>
        <button className="coursebot-close-button" onClick={handleClose}>
          <img src={DrawerIcon.Cross} alt="close-arrow-coursebot" style={{ cursor: 'pointer', width: 24, height: 24 }} />
        </button>
      </div>
      <div className="coursebot-content">
        {isLoading ? (
          <div className="coursebot-loader">
            <div className="loader-spinner"></div>
            <p>Loading chat history...</p>
          </div>
        ) : (
          <>
            <MessageList
              messages={messages}
              isTyping={isTyping}
              showReplaceButton={props.usage === 'course-modify' && showReplaceButton}
              onReplace={handleReplace}
              usage={props.usage}
            />
          </>
        )}
        <div className="chat-footer">
          {props.referenceData && (
            <div className="ref-name-container">
              <div className="ref-name-badge">
                <div className="ref-info">
                  <span className="ref-label">Reference</span>
                  <span className="ref-name">{props.referenceData}</span>
                </div>
              </div>
            </div>
          )}


          <ChatInput
            onSendMessage={handleSendMessage}
            usage={props.usage}
          />
        </div>
      </div>
    </div>
  );
};

export default ChatContainer;