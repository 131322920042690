/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Colors } from "../../../utils/constants/colors";
import { useNavigate } from "react-router-dom";


export default function NotFoundScreen() {

    const navigate = useNavigate()

    useEffect(() => {
        navigate('/', { replace: true });
    }, []);

    return (
        <div className="notfound" style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            fontSize: '28px'
        }}>
            <p style={{ textAlign: 'center', color: Colors.primary, fontWeight: 'bold' }}>
                Page not found
            </p>
            <span style={{fontSize:19}}>
                Redirecting to home....
            </span>
        </div>
    )
}