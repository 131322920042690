import React, { useEffect } from "react";
import './styles.css';
import { AppSvg } from "../../../assets/svg";
import { Button } from "../../../components";
import axios from "axios";
import { ApiCollection } from "../../../config/envConfig";
import errorHandler from "../../../utils/common/errHandler";
import LoadingModal from "../../../components/loading-modal";
import { useDispatch } from "react-redux";
import { setActiveUser } from "../../../redux/userSlice";
import { useNavigate } from "react-router-dom";
import { LocalUser } from "../../../utils/interface/localUser";
import { GoogleLogin } from '@react-oauth/google';

export default function LoginScreen() {
  const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const PASSWORD_REGEX = /^.{8,}$/;

  const dispatch = useDispatch();
  const navigate = useNavigate()

  const [showModal, setShowModal] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");

  useEffect(() => {
    initializeAuth();
   // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const initializeAuth = async () => {


        // if (token) {
        //   await handleUserInfo(token, 'normal');
        // } else {
        //   setShowModal(false);
        //   return
        // }

    
  };



  const handleUserInfo = async (token: string, provider: string) => {

    const headers = {
      "providerToken": token,
      provider:"GOOGLE",
      'version': 'v0',
    }

    await axios.get(`${ApiCollection.user.getAuthUser}`, { headers })
      .then(async (res) => {

        const userInformation = res.data
        if (!userInformation) {
          console.error("User information not found");
          setShowModal(false);
          alert("User information not found");
          return;
        }

        let userInfo = {
          id: userInformation?.id || "",
          email: userInformation?.email || "",
          firstName: userInformation?.first_name || "",
          lastName: userInformation?.last_name || "",
          isActive: userInformation?.is_active || false,
          profilePhoto: userInformation?.picture || "",
          mobileNo: userInformation?.mobile_no || "",
          profileBio: userInformation?.profile_bio || "",
          taskInterests: userInformation?.task_interests || "",
          preferredLanguage: userInformation?.preferredLanguage || "",
          dateOfBirth: userInformation?.date_of_birth || "",
          isPro: userInformation?.is_subscription_active || false,
          isProSelected: userInformation?.is_subscription_active ? true : false, 
          username: userInformation?.username || "",
          skills:userInformation?.skills || [],
          interests: userInformation?.interests || [],
          token:userInformation?.token || "",

        } as LocalUser



        if (userInfo?.isActive) {
          dispatch(setActiveUser(userInfo));
          navigate('/')

        } else {
          alert("User is not active");
        }


        setShowModal(false)


      })
      .catch((err) => {
        setShowModal(false);
        errorHandler('Login', err);
        return;
      })
  }

  const validateFields = () => {
    let isValid = true;
    
    if (!email) {
      setEmailError("Email is required");
      isValid = false;
    } else if (!EMAIL_REGEX.test(email)) {
      setEmailError("Please enter a valid email address");
      isValid = false;
    } else {
      setEmailError("");
    }

    if (!password) {
      setPasswordError("Password is required");
      isValid = false;
    } else if (!PASSWORD_REGEX.test(password)) {
      setPasswordError("Password must contain at least 8 characters");
      isValid = false;
    } else {
      setPasswordError("");
    }

    return isValid;
  };

  const onPressLogin = async () => {
    if (!validateFields()) {
      return;
    }
    
    setShowModal(true);
    try {
      const response = await axios.post(`${ApiCollection.user.login}`, {
        "email": email,
        "password": password
      });
      
      console.log("Response", response)

      const token = response.data.token;
    
      if (token) {
        await handleUserInfo(token, 'normal');
        navigate('/')
      } else {
        alert('Login failed. Please try again.');
      }
    } catch (err) {
      setShowModal(false);
      errorHandler('Login', err);
    }
  };

  const handleGoogleLogin = (credentialResponse: any) => {
    const token = credentialResponse.credential;
    if (token) {
      setShowModal(true);
      handleUserInfo(token, 'google');
    } else {
      console.log('No credential received');
      alert('Login failed. Please try again.');
    }
  };

  const handleForgotPassword = () => {
    navigate('/forget')
  };

  return (
    <div className="login-screen">
      {showModal && <LoadingModal modalVisible={showModal} />}

      <div className="wrapper">
        <img src={AppSvg.logo} className="login-logo" alt="logo" />
        
        <div className="input-container">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError("");
            }}
            className="login-input"
          />
          {emailError && <span className="error-message">{emailError}</span>}
        </div>

        <div className="input-container">
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setPasswordError("");
            }}
            className="login-input"
          />
          {passwordError && <span className="error-message">{passwordError}</span>}
        </div>

        <span 
          className="forgot-password"
          onClick={handleForgotPassword}
        >
          Forgot Password?
        </span>

        <Button
          title="Login"
          onClick={onPressLogin}
          className="login-button"
        />

        <div className="divider">
          <span>OR</span>
        </div>

        <div className="social-login">
            <GoogleLogin
              type="standard"
              size="large"
              width="100%"
              text="continue_with"
              shape="pill"
              useOneTap={false}
              onSuccess={handleGoogleLogin}
              onError={() => {
                console.log('Login Failed');
                alert('Google login failed. Please try again.');
              }}
            />
        </div>

        <div className="signup-text">
          Don't have an account? <span className="signup-link" onClick={() => navigate('/signup')}>Sign up</span>
        </div>
      </div>
    </div>
  )
}