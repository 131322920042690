import React from "react";
import "./styles.css";
import { useAxios } from "../../../../../hooks/useAxios";
import { useNavigate } from "react-router-dom";
import { ApiCollection } from "../../../../../config/envConfig";
import { Colors } from "../../../../../utils/constants/colors";
import { AppSvg } from "../../../../../assets/svg";
import { LearnCourseIcons } from "../../../../../assets/images";
import ChanakyaLearnHeader from "../../../../../components/learn/header";


export default function CreateMyCourse() {

    // useEffect(() => {
    //     getUser()
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);


    const axios = useAxios()
    const navigate = useNavigate();



    const [currentStep, setCurrentStep] = React.useState(0);
    const [answers, setAnswers] = React.useState({
        learningTopic: "",
        learningGoal: "",
        personalGoal: ""
    });



    const questions = [
        {
            id: 0,
            question: "What do you want to learn today?",
            stateKey: "learningTopic",
            image: LearnCourseIcons.LearnBooks,
            suggestions: [
                {
                    title: "Cooking",
                    text: "I want to learn cooking to create delicious meals and understand different culinary techniques",
                    icon: AppSvg.Cooking
                },
                {
                    title: "Learn Coding",
                    text: "I want to learn programming to develop applications and understand software development",
                    icon: AppSvg.Coding
                },
                {
                    title: "Music Compose",
                    text: "I want to learn music composition to create my own songs and understand music theory",
                    icon: AppSvg.MusicIcon
                },
                {
                    title: "Editing",
                    text: "I want to learn video editing to create engaging content and master editing software",
                    icon: AppSvg.EditIcon
                }
            ]
        },
        {
            id: 1,
            question: "Tell us about your learning goals?",
            stateKey: "learningGoal",
            image: LearnCourseIcons.Darthit,
            suggestions: [
                {
                    title: "Interview Preparation",
                    text: "I want to prepare for job interviews and improve my chances of success",
                    icon: AppSvg.InterviewPrep
                },
                {
                    title: "Exam prep",
                    text: "I want to study effectively and achieve good scores in my upcoming exams",
                    icon: AppSvg.ExamPrep
                },
                {
                    title: "Professional Growth",
                    text: "I want to enhance my skills and knowledge for career advancement",
                    icon: AppSvg.Barbell
                },
            ]
        },
        {
            id: 2,
            question: "For the final touch, Tell us about your learning style !",
            stateKey: "personalGoal",
            image: LearnCourseIcons.Headphone,
            suggestions: [
                {
                    title: "Short content",
                    text: "I prefer brief, concise lessons that I can quickly understand and apply",
                    icon: AppSvg.Stack
                },
                {
                    title: "Video Tutorials",
                    text: "I learn best through visual content and step-by-step video demonstrations",
                    icon: AppSvg.Tutorial
                },
                {
                    title: "Humour",
                    text: "I enjoy learning through engaging, fun content with a touch of humor",
                    icon: AppSvg.Smiley
                },
                {
                    title: "Meme References",
                    text: "I connect well with modern, relatable content that includes popular culture",
                    icon: AppSvg.Tree
                }
            ]
        }
    ];


    const handleNext = async () => {
        const currentQuestion = questions[currentStep];
        const currentAnswer = answers[currentQuestion.stateKey as keyof typeof answers];

        console.log(`Answer for Question ${currentStep + 1}:`, {
            question: currentQuestion.question,
            answer: currentAnswer
        });

        if (currentStep < questions.length - 1) {
            setCurrentStep(currentStep + 1);
        } else {
            try {
                setIsLoadingUser(true);

                const response = await axios.post(ApiCollection.chanakyaGuru.createCrashCourse, {
                    skill_level: answers.learningTopic,
                    vision_goal: answers.learningGoal,
                    learning_style: answers.personalGoal,
                    persona: "python"
                });

                if (response.data) {
                    console.log("Course creation successful:", response.data);
                    navigate('/learn/course/mycourse');
                }
            } catch (error) {
                console.error("Failed to create course:", error);
                // Handle error (you might want to show an error message to the user)
            } finally {
                setIsLoadingUser(false);
            }
        }
    };

    const canProceed = () => {
        return answers[questions[currentStep].stateKey as keyof typeof answers] !== "";
    };

    const [isLoadingUser, setIsLoadingUser] = React.useState(false);


    // const getUser = async () => {
    //     setIsLoadingUser(true);
    //     await axios.get(ApiCollection.user.getUser)
    //         .then((res) => {
    //             console.log(res.data);
    //             setIsLoadingUser(false);

    //             const bio = res.data.user_bio;
    //             if (bio !== "") {

    //             } else {

    //             }
    //         })
    //         .catch((err) => {
    //             console.warn(err);
    //             setIsLoadingUser(false);
    //         })
    // }



    // const onLogoPress = () => {
    //     navigate('/learn/course/list')
    // }


    const handleAnswerUpdate = (value: string) => {
        const newAnswers = {
            ...answers,
            [questions[currentStep].stateKey]: value
        };
        setAnswers(newAnswers);
        console.log("Updated answer:", {
            question: questions[currentStep].question,
            answer: value
        });
    };

    return (
        !isLoadingUser ?
            <div className="course-create-screen">
                <ChanakyaLearnHeader />
                <div className="content">
                    <p>Let's create your</p>
                    <h1 style={{color:Colors.primary,fontSize:'1.6rem',marginTop:0}}>Personalized Course!</h1>
                    <hr className="divider" />
                    <div className="question-container">
                        {
                            (currentStep === 0 || currentStep === 2) &&
                            <img
                                src={questions[currentStep].image}
                                alt={`Question ${currentStep + 1}`}
                                className="question-image left"
                            />
                        }

                        <h2>{questions[currentStep].question}</h2>
                        <textarea
                            value={answers[questions[currentStep].stateKey as keyof typeof answers]}
                            onChange={(e) => handleAnswerUpdate(e.target.value)}
                            placeholder="Type your answer here..."
                        />
                        <div className="suggestions">
                            {questions[currentStep].suggestions.map((suggestion) => (
                                <button
                                    key={suggestion.title}
                                    className="suggestion-box"
                                    onClick={() => handleAnswerUpdate(suggestion.text)}
                                >
                                    <img
                                        src={suggestion.icon}
                                        alt={suggestion.title}
                                        className="suggestion-icon"
                                    />
                                    {suggestion.title}
                                </button>
                            ))}
                        </div>
                        {
                            (currentStep === 1) &&
                            <img
                                src={questions[currentStep].image}
                                alt={`Question ${currentStep + 1}`}
                                className="question-image right"
                            />
                        }
                        {canProceed() && (
                            <button
                                className="next-button"
                                onClick={handleNext}
                            >
                                {currentStep === questions.length - 1 ? 'Let the Magic Begin!' : 'Next'}
                            </button>
                        )}
                    </div>
                </div>
            </div>
            :
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <h2 style={{ color: Colors.primary }}>Analyzing...</h2>
            </div>
    )
}