import React from "react";
import './styles.css'



interface SidePanelProps {
    children: React.ReactNode;
    isOpen: boolean;
    onClose: () => void;
    title: string;
    icon: string;
    headerShown?: boolean;
}


export default function SidePanel(props: SidePanelProps) {


    // const onClose = () => {
    //     props.onClose();
    // }

    return (
        <div className={`module-sidepanel ${props.isOpen ? 'open' : 'close'}`}>
            {/* <img src={LearnIcons.Cross} alt="close" onClick={onClose} style={{ cursor: 'pointer',width : '30px', height : '30px' }} /> */}
            {props.children}
        </div>
    );
}