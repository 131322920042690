import React, { CSSProperties, ReactNode } from "react";
import { LearnIcons } from "../../../assets/images";
import './styles.css'

interface CanvasProps {
    onClose: () => void;
    title: string;
    icon?: string;
    children: ReactNode;
    titleStyle?: CSSProperties;
    showCanvas?: boolean;
    isLoading?: boolean;
    hideClose?: boolean;
    containerClassName?: string;
}


export default function Canvas(props: CanvasProps) {

    const onClose = () => {
        props.onClose();
    }

    if (props.showCanvas === false && props.isLoading === false) {
        return null;
    }

    const containerClassName = props.containerClassName ? `canvas-container ${props.containerClassName}` : "canvas-container";

    if (props.isLoading) {
        return (
            <div className={containerClassName}>
                <div className="canvas-title">
                    <div className="canvas-title-holder">
                        {props.icon && <img src={props.icon} alt="easier" />}
                        <span color="black">Generating ...</span>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
                    {props.icon && <img src={props.icon} alt="easier" className="genearing-easier" />}

                    <p style={{ width: '80%', textAlign: 'center' }}>
                        Please wait while we generate content for you !
                    </p>
                </div>
            </div>
        )
    }

    return (
        <div className={containerClassName}>
            <div className="canvas-title">
                <div className="canvas-title-holder">
                    {props.icon && <img src={props.icon} alt="easier" />}
                    <span
                        style={props.titleStyle}
                    >{props.title}</span>
                </div>
                {props.hideClose ? null : <img src={LearnIcons.Cross} alt="close" onClick={onClose} />}

            </div>
            <div className="canvas-content-holder">
                {props.children}
            </div>
        </div>
    )
}