import React from "react";
import { LearnIcons } from "../../../assets/images";
import "./styles.css";

interface ChatBotButtonProps {
    showBot: boolean;
    onPress: () => void;
}


export default function ChatBotButton(props: ChatBotButtonProps) {

    return (
        <button
            className="coursebot-button"
            onClick={() => {
                if (props.onPress) {
                    props.onPress()
                }
            }}
            style={{ display: props.showBot ? 'none' : 'flex' }}
        >
            <img src={LearnIcons.AskBot} alt="easier" className="bot-icon" style={{ marginRight: 10, width: 25, height: 25 }} />
            Ask Chanakya !
        </button>
    )
}