import React from 'react';
import ReactMarkdown from 'react-markdown';
// import { useUser } from '../../redux/useStore';

interface MessageProps {
  message: {
    id: string;
    text: string;
    isBot: boolean;
    file?: {
      url: string;
      type: string;
      name: string;
    };
  };
}

const Message: React.FC<MessageProps> = ({ message }) => {
  

  

  const renderFilePreview = () => {
    if (!message.file) {
      
      return null;
    }



    if (message.file.type.startsWith('image/')) {
      return (
        <div className="message-file-preview">
          <img 
            src={message.file.url} 
            alt={message.file.name}
            className="message-image-preview"
            onError={(e) => console.error("Image failed to load:", e)} // Debug log
          />
          <div className="file-name">{message.file.name}</div>
        </div>
      );
    }

    if (message.file.type.startsWith('audio/')) {
      return (
        <div className="message-file-preview">
          <div>
            <audio controls>
              <source src={message.file.url} type={message.file.type} />
              Your browser does not support the audio element.
            </audio>
          </div>
          <div className="file-name">{message.file.name}</div>
        </div>
      );
    }

    return null;
  };

  const UserAvatar = () => (
    <svg 
      className="sender-avatar"
      viewBox="0 0 24 24" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="11" fill="#E0E0E0" />
      <path 
        d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z" 
        fill="white"
      />
      <path 
        d="M18 19C18 16.2386 15.3137 14 12 14C8.68629 14 6 16.2386 6 19" 
        stroke="white" 
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );

  return (
    <div className={`coursebot-message ${message.isBot ? 'bot' : 'user'}`}>
      <div className="coursebot-message-content">
        <div className="message-sender">
          {message.isBot ? (
            'Chanakya -'
          ) : (
            <>
              <UserAvatar />
              User -
            </>
          )}
        </div>
        {renderFilePreview()}
        {message.text && (
          message.isBot ? (
            <ReactMarkdown>{message.text}</ReactMarkdown>
          ) : (
            <div className="user-message-text">{message.text}</div>
          )
        )}
      </div>
    </div>
  );
};

export default Message;