import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './index.css';
import { AppSvg } from '../../../../assets/svg';
import { useUserApi } from '../../../../hooks/user';
import { useUser } from '../../../../redux/useStore';
import { setActiveUser } from '../../../../redux/userSlice';
import { useDispatch } from 'react-redux';
import { LocalUser } from '../../../../utils/interface/localUser';
import { ApiCollection } from '../../../../config/envConfig';
import { useAxios } from '../../../../hooks/useAxios';


const LearnScreen: React.FC = () => {

  const user = useUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const axios = useAxios()
  const { getUserInfo } = useUserApi();


  useEffect(() => {
    checkOnboarding();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const [persona, setPersona] = useState('');
  const [isLoading, setIsLoading] = useState(false);



  const checkOnboarding = async () => {
    setIsLoading(true);
    const res = await getUserInfo();
    console.log('res:', res);
    setIsLoading(false);
    if (res && res.profileBio) {
      if (res.profileBio.trim() !== '') {
        navigate('/learn/course/list', { replace: true });
      }
    }
  }


  const handleNext = async () => {

    if (persona.trim() === '') {
      alert('Please enter your persona');
      return;
    }

    setIsLoading(true);

    await axios.post(ApiCollection.chanakyaGuru.userUpdate, {
      profileBio: persona
    })
      .then(res => {
        setIsLoading(false);

        dispatch(setActiveUser({
          ...user,
          profileBio: persona
        } as LocalUser));
        navigate('/learn/course/list', { replace: true });
      })
      .catch(err => {
        setIsLoading(false);
        alert(err || 'Something went wrong');
        console.log('Error updating user info:', err);
      })

  };




  return (
    <>
      <header className="onboarding-learn-header">
        <img src={AppSvg.chanakyaLogo} alt="logo" className="header-logo" />
      </header>
      <div className="learn-container">
        <div className="learn-content-wrapper">

          <div className="learn-text-container">
            <h1 className="learn-content">Tell us about youself !</h1>
            <p className="learn-text">This will help us deliver you personalized content, <br />making your learning journey 10x better!</p>
            <div className="interests-input-container">
              <textarea
                className="skills-textarea"
                placeholder="Ex - I am react developer with 3y exp..."
                value={persona}
                onChange={(e) => setPersona(e.target.value)}
                rows={4}
              />
            </div>
            <button
              className="continue-button"
              onClick={handleNext}
              disabled={isLoading || persona.trim() === ''}
            >
              <strong>Continue</strong>
            </button>
          </div>

        </div>
      </div>
    </>
  );
};

export default LearnScreen;
