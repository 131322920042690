import React, { useEffect } from "react";
import './styles.css';
import ChanakyaLearnHeader from "../../../../../components/learn/header";
import { LearnIcons } from "../../../../../assets/images";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAxios } from "../../../../../hooks/useAxios";
import { ApiCollection } from "../../../../../config/envConfig";
import { AppSvg } from "../../../../../assets/svg";
import CodeRenderer from "../../../../../components/learn/codeRenderer";
import { Colors } from "../../../../../utils/constants/colors";
import MarkdownRenderer from "../../../../../components/markdownRenderer";
import { ChatBotButton, ImageModal, LanguagePicker, QuizModal } from "../../../../../components/learn";
import type { ContentItem, CourseModule, ModuleSection } from "../../../../../utils/interface/courseModule";
import { useDispatch } from "react-redux";
import ChatContainer from "../../../../../components/CourseBot/ChatContainer";
import { clearCourseBotContext } from "../../../../../redux/coursebotSlice";
import { useCourseBotContext } from "../../../../../redux/useStore";
import SelectionMenu from "../../../../../components/learn/selectionMenu";
import Canvas from "../../../../../components/learn/canvas";
import SidePanel from "../../../../../components/learn/sidepanel";



export default function CourseModuleScreen() {

    useEffect(() => {
        setTimeout(() => {
            markModuleAsComplete();
        }, 1000 * 60)
        // eslint-disable-next-line react-hooks/exhaustive-deps

        const isFirstT = localStorage.getItem("isFirstTime");
        if (isFirstT === null) {
            setIsFirstTime(true);
            localStorage.setItem("isFirstTime", "true");
        } else {
            if (isFirstT === "true") {
                setIsFirstTime(true);
            } else {
                setIsFirstTime(false);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);




    const [searchParams] = useSearchParams();
    const axios = useAxios();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const courseBotContext = useCourseBotContext();

    const enrollmentId = searchParams.get('enrollmentId');
    const moduleId = searchParams.get('moduleId');

    const [module, setModule] = React.useState<CourseModule | null>(null);
    const [isLoading, setIsLoading] = React.useState(false);
    // const [indexes, setIndexes] = React.useState<{
    //     id: string,
    //     title: string,
    // }[]>([]);



    const [isRead, setIsRead] = React.useState(false);

    const [makingEasier, setMakingEasier] = React.useState(false);
    const [easierText, setEasierText] = React.useState<any>(null);

    const [selectedImage, setSelectedImage] = React.useState<any>(null);
    const [showImage, setShowImage] = React.useState(false);

    const [makingVoiceGuide, setMakingVoiceGuide] = React.useState(false);
    const [voiceGuide, setVoiceGuide] = React.useState<any>(null);
    const [showTranscript, setShowTranscript] = React.useState(false);
    const [showLanguagePicker, setShowLanguagePicker] = React.useState(false);
    const [language, setLanguage] = React.useState<any>('English');

    const [showQuiz, setShowQuiz] = React.useState(false);
    const [quizData, setQuizData] = React.useState<any[]>([]);

    const [isFirstTime, setIsFirstTime] = React.useState(true);

    const [sidepanelActive, setSidepanelActive] = React.useState(false);
    const [showBot, setShowBot] = React.useState(false);

    const [playedPercentage, setPlayedPercentage] = React.useState<any>(0);
    const [referenceData, setReferenceData] = React.useState<any>(null);

    useEffect(() => {
        getModuleContent();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [moduleId, enrollmentId]);

    // const sectionRefs = useRef<(HTMLDivElement | null)[]>([]);
    // useEffect(() => {

    //     window.addEventListener('scroll', () => {
    //         // Define the callback function for the observer
    //         const handleIntersection = (entries: any) => {
    //             // console.log(entries);
    //             entries.forEach((entry: any) => {
    //                 // console.log(entry);
    //                 if (entry.isIntersecting) {
    //                     console.log(`Section ${entry.target.id} is in view!`);
    //                     setCurrentIndex(entry.target.id);
    //                     // Run your desired function here for each section
    //                     // For example, you could update state or trigger animations
    //                 }
    //             });
    //         };

    //         // Create the intersection observer with options
    //         const observer = new IntersectionObserver(handleIntersection, {
    //             root: null, // Use the viewport as the root
    //             threshold: 0.6, // Trigger when 50% of the section is visible
    //         });

    //         // Observe each section
    //         sectionRefs.current.forEach((section) => {
    //             if (section) observer.observe(section);
    //         });

    //         // Cleanup the observer on component unmount
    //         return () => observer.disconnect();

    //     })

    // }, [enrollmentId, moduleId]);



    const getModuleContent = async () => {
        setIsLoading(true);
        await axios.get(`${ApiCollection.chanakyaGuru.moduleDetail}?enrollment_id=${enrollmentId}&module_id=${moduleId}`)
            .then((res) => {
                console.warn("res", res.data);
                setModule(res.data.data);

                // const moduleIndexes = res.data.data.currentModule.content.map((item: any, i: number) => ({
                //     id: i + 1,
                //     title: item.subHeading,
                // }));
                // setIndexes(moduleIndexes);


                if (courseBotContext !== null) {
                    dispatch(clearCourseBotContext())
                }


                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
                setModule(null);
            })
    }

    const markModuleAsComplete = async () => {
        if (isRead) return;

        await axios.post(ApiCollection.chanakyaGuru.markModuleComplete, {
            enrollment_id: enrollmentId,
            module_id: moduleId
        })
            .then((res) => {
                console.log("res", res?.data);
                console.warn("Module Marked as Complete !");
                setIsRead(true);
            })
            .catch((err) => {
                console.log("Mark Complete Error -", err.response?.data?.detail);
            })
    }

    const makeItEasier = async (item: ModuleSection, content: string) => {
        // console.log(item);

        if (sidepanelActive) {
            return;
        }


        setIsFirstTime(false);
        localStorage.setItem("isFirstTime", "false");

        setMakingEasier(true);
        setSidepanelActive(true);
        const heading = item.subHeading;
        const contentText = content
        console.log(contentText);

        await axios.post(ApiCollection.chanakyaGuru.makeItEasier, {
            "context": contentText,
        })
            .then((res) => {
                console.log("res", res.data.simplified_content);
                setEasierText({
                    heading: heading,
                    text: res.data.simplified_content,
                    realText: contentText
                });
                setMakingEasier(false);

            })
            .catch((err) => {
                console.log(err);
                setMakingEasier(false);

            })

    }

    const onAskChanakya = (item: any,selectedText:any) => {

        setSidepanelActive(true);
        setShowBot(true);

        // const contentText = item.content.map((content: any) => content.data).join("\n");
        // console.log(contentText);
        const contentText = selectedText;
        setReferenceData(contentText);


    }

    const onVoiceGuide = async (item: ModuleSection, content: string) => {

        if (sidepanelActive) {
            return;
        }

        setIsFirstTime(false);
        localStorage.setItem("isFirstTime", "false");

        setMakingVoiceGuide(true);
        setSidepanelActive(true);
        const heading = item.subHeading;
        const contentText = content
        console.log(contentText);

        await axios.post(ApiCollection.chanakyaGuru.voiceGuide, {
            "text": contentText,
            "lang": language || "English"
        })
            .then((res) => {
                console.log("res", res.data);
                setVoiceGuide({
                    heading: heading,
                    url: res.data.audio_url,
                    transcription: res.data.transcription,
                    content: content
                });
                setMakingVoiceGuide(false);
            })
            .catch((err) => {
                console.log(err.response.data.detail || "Error in Voice Guide !");
                setMakingVoiceGuide(false);
            })
    }


    const onNextModule = () => {
        const searchParams = new URLSearchParams({
            enrollmentId: enrollmentId || '',
            moduleId: module?.nextModule.id || '',
        }).toString();
        navigate(`/learn/course/module?${searchParams}`);
    }

    const onBackModule = () => {
        const searchParams = new URLSearchParams({
            enrollmentId: enrollmentId || '',
            moduleId: module?.prevModule.id || '',
        }).toString();
        navigate(`/learn/course/module?${searchParams}`);
    }


    const onImagePress = (url: string) => {
        setSelectedImage(url);
        setShowImage(true);
        // console.log(url);
    }


    const onLangSelector = () => {
        setShowLanguagePicker(true);

    }

    const renderChanakyaAssist = (selectedText: string, item: any) => {



        return (
            <div className="chanakya-assist-menu">
                <h4>Chanakya Assist</h4>
                <p>
                    Too hard to understand or having some doubts ? I am here to help !
                </p>
                <div className="chanakya-assist-menu-options">
                    <div className={`chanakya-assist-menu-option ${sidepanelActive ? "option-disabled" : ""}`} onClick={() => makeItEasier(item, selectedText)}>
                        <img src={LearnIcons.MagicAi} alt="easier" />
                        <span>Easier Explain</span>
                    </div>

                    <div className={`chanakya-assist-menu-option ${sidepanelActive ? "option-disabled" : ""}`} onClick={() => onAskChanakya(item,selectedText)}>
                        <img src={LearnIcons.AskBot} alt="easier" />
                        <span>Ask Chanakya</span>
                    </div>
                    <div className={`chanakya-assist-menu-option ${sidepanelActive ? "option-disabled" : ""}`} onClick={() => onVoiceGuide(item, selectedText)}>
                        <img src={LearnIcons.VoiceGuide} alt="easier" />
                        <span>Voice Guide</span>
                    </div>
                </div>
            </div>
        )
    }


    const replaceWithEasier = (easierText: any) => {

        const content = module?.currentModule.content.find((item: any) => item.subHeading === easierText.heading);
        content?.content.forEach((item: any) => {
            if (item.type === "text") {
                if (item.data.includes(easierText.realText)) {
                    item.data = item.data.replace(easierText.realText, easierText.text);
                }
            }
        })

        setEasierText(null);
        setSidepanelActive(false);



    }



    const handleTimeUpdate = (event: any) => {
        const audioElement = event.target;
        const percentPlayed = (audioElement.currentTime / audioElement.duration) * 100;
        setPlayedPercentage(percentPlayed.toFixed(2)); // Limit to 2 decimal places
    };

    const ColoredText = ({ givenText, percentage }: any) => {
        // Calculate the number of characters to color red


        const text = givenText.replace(new RegExp('<break time="1.0s" />', 'g'), '')


        const redLength = Math.floor((percentage / 100) * text.length);

        // Split the text based on the calculated length
        const redText = text.substring(0, redLength);
        const remainingText = text.substring(redLength);

        return (
            <p className="transcript-holder">
                <span className="transcript completed-trans">{redText}</span>
                <span className="transcript incomplete-trans">{remainingText}</span>
            </p>
        );
    };


    return (
        !isLoading ?
            module ?
                <div className="module-screen">
                    <ChanakyaLearnHeader />
                    {
                        showImage &&
                        <ImageModal url={selectedImage}
                            onClose={() => setShowImage(false)}
                        />
                    }

                    {
                        showLanguagePicker &&
                        <LanguagePicker
                            selectedLanguage={language}
                            onSelect={async (lang: any) => {
                                console.log(lang);
                                setLanguage(lang.value);
                                setShowLanguagePicker(false);
                                setPlayedPercentage(0);
                                setMakingVoiceGuide(true);
                                await axios.post(ApiCollection.chanakyaGuru.voiceGuide, {
                                    "text": voiceGuide.content,
                                    "lang": lang.value || "English"
                                })
                                    .then((res) => {
                                        console.log("res", res.data);
                                        setVoiceGuide({
                                            heading: '',
                                            url: res.data.audio_url,
                                            transcription: res.data.transcription,
                                            content: voiceGuide.content
                                        });
                                        setMakingVoiceGuide(false);
                                    })
                                    .catch((err) => {
                                        console.log(err.response.data.detail || "Error in Voice Guide !");
                                        setMakingVoiceGuide(false);
                                    })
                            }}
                            onClose={() => setShowLanguagePicker(false)} />
                    }
                    {
                        showQuiz &&
                        <QuizModal
                            quiz={quizData}
                            onClose={() => setShowQuiz(false)}
                        />
                    }

                    <div className={`module-content-holder ${isFirstTime && 'intro'}`}>
                        <div className={`course-module-content ${isFirstTime ? 'intro' : ''} ${sidepanelActive ? 'with-sidepanel' : ''}`}>
                            <span className="course-title">{module.currentModule.course_name || ''}</span>
                            <h2 className="course-module-title">{module.currentModule.moduleHeading}</h2>

                            {
                                module.currentModule.content.length > 0 ?
                                    module.currentModule.content.map((item: ModuleSection, i: number) => (
                                        <SelectionMenu
                                            onTextSelect={(text) => console.log('Selected:', text)}
                                            menuContent={(props) => renderChanakyaAssist(props.text, item)}
                                        >
                                            <div className="submodule-container"
                                                key={i}
                                                id={`${i + 1}`}
                                            // ref={(el) => (sectionRefs.current[i] = el)}
                                            >
                                                <h3 className="submodule-title">{item.subHeading}</h3>

                                                {
                                                    item.content.length > 0 ?
                                                        item.content.map((content: ContentItem, i: number) => (
                                                            content.type === "text" ?
                                                                <MarkdownRenderer key={i} markdownText={content.data} className="markdown-content" />
                                                                :
                                                                content.type === "image" ?
                                                                    <img onClick={() => onImagePress(content.data)} key={i} src={content.data} className="submodule-image" alt="content" />
                                                                    :
                                                                    content.type === "video" ?
                                                                        <video key={i} src={content.data} className="submodule-video" controls />
                                                                        :
                                                                        content.type === "code" ?
                                                                            <CodeRenderer key={i} code={`${content.data}`} />
                                                                            // <video key={i} src={"http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"} className="submodule-video" controls />
                                                                            :
                                                                            content.type === "quiz" &&
                                                                            <div className="submodule-quiz-cta-holder" style={{
                                                                                marginTop: 10, width: '70%',
                                                                                display: 'flex',
                                                                                flexDirection: 'column', alignItems: 'flex-start',
                                                                                justifyContent: 'flex-start',
                                                                                padding: 10,
                                                                                paddingLeft: 0,
                                                                                marginBottom: 20,

                                                                                borderRadius: 10

                                                                            }}>
                                                                                <h3 style={{ color: Colors.primary, marginBottom: 5 }}> New Chanakya Challenge !</h3>
                                                                                <p style={{ color: 'gray', width: '100%' }}>
                                                                                    Want to test your knowledge ? Take a small quiz to analyze how much do you understand the topic !
                                                                                </p>
                                                                                <div className="submodule-option" style={{ marginTop: 10 }}
                                                                                    onClick={() => {
                                                                                        setQuizData(content.data);
                                                                                        setShowQuiz(true);
                                                                                    }}>

                                                                                    <img className="submodule-option-image" src={LearnIcons.Puzzle} alt="easier" />
                                                                                    <span className="submodule-option-name">Show Quiz !</span>
                                                                                </div>
                                                                            </div>

                                                        ))
                                                        :
                                                        <div style={{ marginTop: 10 }} className="submodule-description">No Content Found !</div>
                                                }

                                            </div>
                                        </SelectionMenu>
                                    ))
                                    :
                                    <div className="submodule-container">
                                        <h3 className="submodule-title">No Content Found !</h3>
                                    </div>
                            }


                            <div className="course-module-footer">
                                {module.prevModule.name ?
                                    <div className="course-module-footer-button back" onClick={onBackModule}>
                                        <div className="course-module-footer-button-title-holder back">

                                            <img src={LearnIcons.PrevModule} style={{ marginRight: 10, width: 20, height: 20 }} alt="back" />
                                            <span>Back</span>
                                        </div>
                                        <h4>{module.prevModule.name}</h4>
                                    </div>
                                    :
                                    <div></div>
                                }

                                {
                                    module.nextModule.name ?
                                        <div className="course-module-footer-button" onClick={onNextModule}>

                                            <div className="course-module-footer-button-title-holder next">
                                                <span>Next</span>
                                                <img src={LearnIcons.NextModule} style={{ marginLeft: 10, width: 20, height: 20 }} alt="as" />
                                            </div>
                                            <h4>{module.nextModule.name}</h4>
                                        </div>
                                        :
                                        <></>
                                }

                            </div>

                        </div>

                        {/* ------------------------ TUTORIAL BOX ------------------------ */}
                        {
                            isFirstTime &&
                            <div className="course-help-intro">
                                <h3>Your Course, Your Way !</h3>
                                <p>
                                    Select the area you want help with, and customize as per your need.

                                    With Chanakya AI, you can customize and update your course the way you like !
                                </p>
                                <div className="close-intro" onClick={() => {
                                    setIsFirstTime(false)
                                    localStorage.setItem("isFirstTime", "false");
                                }}>
                                    Close
                                </div>
                                <div className="course-help-intro-media">
                                </div>
                            </div>
                        }


                        {/* ------------------------ EASIER TEXT CANVAS ------------------------ */}

                        <Canvas
                            showCanvas={easierText !== null}
                            isLoading={makingEasier}
                            onClose={() => {
                                setEasierText(null);
                                setSidepanelActive(false);
                            }}
                            title="Easier Explanation"
                            icon={LearnIcons.MagicAiPurple}>
                            {
                                easierText !== null &&
                                <>
                                    <MarkdownRenderer markdownText={easierText.text} />
                                    <span className="easier-text-replacer" onClick={() => replaceWithEasier(easierText)}>Replace this in course !</span>
                                </>
                            }

                        </Canvas>


                        {/* ------------------------ Voice Guide Canvas------------------------ */}

                        <Canvas
                            showCanvas={voiceGuide !== null}
                            isLoading={makingVoiceGuide}
                            onClose={() => {
                                setVoiceGuide(null);
                                setSidepanelActive(false);
                            }}
                            title="Voice Guide"
                            icon={LearnIcons.VoiceGuide}>

                            {
                                voiceGuide !== null &&

                                <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                    <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
                                        <audio controls style={{ width: '80%' }}
                                            onTimeUpdate={handleTimeUpdate}
                                        >
                                            <source src={voiceGuide.url} type="audio/mpeg" />
                                            Your browser does not support the audio element.
                                        </audio>

                                        <span onClick={() => onLangSelector()} className="voice-lang">{language || "EN"}</span>
                                    </div>
                                    {showTranscript &&
                                        <>
                                            <br />
                                            <ColoredText givenText={voiceGuide.transcription} percentage={playedPercentage} />
                                        </>
                                    }


                                    <span className="easier-text-replacer"
                                        onClick={() => setShowTranscript(!showTranscript)}>{showTranscript ? "Hide Transcript" : "Show Transcript"}</span>
                                </div>

                            }

                        </Canvas>


                        {/* ====== COURSE BOT FOR ASK CHANAKYA ========= */}
                        <SidePanel
                            isOpen={showBot}
                            title="Ask Chanakya"
                            icon={LearnIcons.AskBot}
                            onClose={() => {
                                setShowBot(false)
                                setSidepanelActive(false)
                            }}
                        >
                            <ChatContainer
                                usage="course-help"
                                sourcUrl={ApiCollection.chanakyaGuru.courseBot}
                                referenceData={referenceData}
                                onClose={() => {
                                    setShowBot(false)
                                    setSidepanelActive(false)
                                    setReferenceData(null);
                                }}
                            />
                        </SidePanel>



                    </div>

                    {
                        sidepanelActive === false &&
                        <ChatBotButton
                            showBot={showBot}
                            onPress={() => {
                                setShowBot(true)
                                setSidepanelActive(true)
                            }}
                        />
                    }

                </div>
                :
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100vh",
                    }}
                >
                    <span>Module Not Found !</span>
                </div>
            :
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100vh",
                }}
            >
                <img
                    src={AppSvg.loading}
                    alt="loading"
                    style={{ width: "30px", height: "30px" }}
                />
            </div>
    )
}