import React, { useEffect } from "react";
import './styles.css';
import { AppSvg } from "../../../../../assets/svg";
import { CourseCard } from "../../../../../components/learn";
import { useNavigate } from "react-router-dom";
import { useAxios } from "../../../../../hooks/useAxios";
import { ApiCollection } from "../../../../../config/envConfig";
import { BasicCourseDetail } from "../../../../../utils/interface/course";
import ChanakyaLearnHeader from "../../../../../components/learn/header";
import { LearnIcons } from "../../../../../assets/images";

export default function CourseList() {

    const axios = useAxios()

    useEffect(() => {
        getCourses()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getCourses = async () => {

        setIsLoading(true);
        await axios.get(ApiCollection.chanakyaGuru.courseList)
            .then((res) => {

                if (res.data.data.length > 0) {
                    setCourses(res.data.data);
                }
                console.log('res', res.data.data)
                setIsLoading(false);
            })
            .catch((err) => {
                console.log('err', err)
                setIsLoading(false);
            })
    }

    const navigate = useNavigate();

    const [courses, setCourses] = React.useState<BasicCourseDetail[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);

    const onCourseClick = (course: any) => {
        navigate(`/learn/course/detail/${course.id}`);
    }

    return (
        !isLoading ?
            <div className="course-list-screen">

                <ChanakyaLearnHeader />
                <div className="course-list-body">
                    <div className="course-list-heading-holder-list">
                        <span>Here are some</span>
                        <h2>Personalized Courses !</h2>
                    </div>
                    <div className="course-list-item-holder-list">
                        {courses.length > 0 ?
                            courses.map((course, index) => (
                                <CourseCard
                                    key={course.id}
                                    course={course}
                                    index={index}
                                    onCourseClick={onCourseClick}
                                />
                            ))
                            :
                            <div style={{ minHeight: 200, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', height: '100%' }}>
                                <span style={{ marginBottom: 20 }}>No Courses Found</span>
                            </div>
                        }
                    </div>

                    <div className="course-list-bottom-bar">
                        <div className="course-list-bottom-bar-right">
                            <h3>Still Looking for something else ?</h3>
                            <span>😎 Let’s create your course with your styles !</span>
                        </div>
                        <div className="course-list-bottom-bar-left">
                            <div className="course-list-bottom-bar-left-button" onClick={() => navigate('/learn/course/create')}>
                                <img src={LearnIcons.MagicAiWhite} alt="plus" style={{ width: 25, height: 25 }} />
                                <span>Create Course</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            :
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh' }}>
                <img src={AppSvg.loading} alt="loading" style={{ width: '30px', height: '30px' }} />
            </div>
    )
}
