import logo from "./logo.svg";
import loading from "./loading.svg";
import menuClose from "./menuClose.svg";
import newChat from "./newChat.svg";
import message from "./message.svg";
import help from "./help.svg";
import settings from "./settings.svg";
import upgrade from "./upgrade.svg";
import plus from "./Plus.svg";
import logoShort from "./logoShort.svg";
import send from "./send.svg";
import chanakyaLogo from "./chanakyaLogo.svg";
import pro from "../svg/pro.svg";
import premiumLogo from "./premiumPer.svg";
import Tick from "./Tick.svg";
import downArrow from "./downArrow.svg";
import star from "./star.svg";
import infinite from "./infinite.svg";
import flagIcon from "./flag.svg";
import copyIcon from "./copyIcon.svg";
import Speaker from "./speaker.svg";
import muteSpeaker from "./speakerMute.svg";
import cross from "./cross.svg";
import alert from "./alert.svg";
import ChanakyaLogoSmall from "./ChanakayLogoSmall.svg";
import Paperclip from "./paperclip.svg";
import AudioSvg from "./audio.svg";
import imageSvg from "./image.svg";
import PlaySvg from "./play.svg";
import PlayFill from "./play-fill.svg";
import Rewards from "./rewards.svg";
import LevelUp from "./levelUp.svg";
import Certificate from "./certificate.svg";
import checkCircle from "./CheckCircle.svg";
import plusCircle from "./PlusCircle.svg";
import Coding from "./coding.svg";
import Cooking from "./cook.svg";
import MusicIcon from "./musicIcon.svg";
import EditIcon from "./editing.svg";
import InterviewPrep from "./prep.svg";
import Barbell from "./Barbell.svg";
import ExamPrep from "./examPrep.svg";
import Smiley from "./Smiley.svg";
import Stack from "./Stack.svg";
import Tree from "./tree.svg";
import Tutorial from "./tutorial.svg";

export const AppSvg = {
  logo,
  PlaySvg,
  PlayFill,
  loading,
  send,
  chanakyaLogo,
  pro,
  premiumLogo,
  Tick,
  downArrow,
  star,
  infinite,
  copyIcon,
  flagIcon,
  Speaker,
  muteSpeaker,
  cross,
  alert,
  ChanakyaLogoSmall,
  Paperclip,
  AudioSvg,
  imageSvg,
  Rewards,
  LevelUp,
  Certificate,
  plusCircle,
  checkCircle,
  Coding,
  Cooking,
  MusicIcon,
  EditIcon,
  InterviewPrep,
  Barbell,
  ExamPrep,
  Smiley,
  Stack,
  Tree,
  Tutorial,
};

export const DrawerSvg = {
  menuClose,
  newChat,
  message,
  help,
  settings,
  upgrade,
  plus,
  logoShort,
};
