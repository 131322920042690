import { FC, useEffect, useState } from "react";
import { AppSvg } from "../../../../../assets/svg";
import { useNavigate } from "react-router-dom";
import { ApiCollection } from "../../../../../config/envConfig";
import { useAxios } from "../../../../../hooks/useAxios";
import "./index.css";
import SidePanel from "../../../../../components/learn/sidepanel";
import ChatContainer from "../../../../../components/CourseBot/ChatContainer";
import { LearnIcons } from "../../../../../assets/images";
import { ChatBotButton } from "../../../../../components/learn";

interface CourseGenerationProps { }

interface Module {
  id: string;
  title: string;
  content: string[];
}

interface CourseData {
  skill_analysis: string;
  course_overview: string;
  course_name: string;
  modules: Module[]; // when chanakya ( in the finalise course bot ) 
  //upadates the module the response structure changes to this  
  // ** modules : { modules: Module[] } **
  is_checked: boolean;
}

const MyCourse: FC<CourseGenerationProps> = () => {


  const navigate = useNavigate();
  const axios = useAxios();
  const [courseData, setCourseData] = useState<CourseData | null>(null);
  const [loading, setLoading] = useState(true);
  const [sidepanelActive, setSidepanelActive] = useState(false);
  const [showBot, setShowBot] = useState(false);


  useEffect(() => {
    const fetchCrashCourse = async () => {
      try {
        setLoading(true);
        const { data } = await axios.get(ApiCollection.chanakyaGuru.createCrashCourse);

        console.log("Crash Course Data", data)
        console.log(data);
        setCourseData(data);
      } catch (error) {
        console.error('Error fetching crash course:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCrashCourse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLogoPress = () => {
    navigate("/learn/course/list");
  };


  const handleFinalize = async () => {

    console.log(courseData,"course Data")
    try {
      setLoading(true);
      
      const payload = {
        course_overview: courseData?.course_overview,
        course_name: courseData?.course_name,
        skill_analysis: courseData?.skill_analysis,
        modules: courseData?.modules,
      };
      const { data } = await axios.post(
        ApiCollection.chanakyaGuru.finalizeCourse,
        payload
      );
      console.log("Finalized course response:", data);
      navigate("/learn/course/list");
    } catch (error) {
      console.error("Error finalizing course:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCrashCourse = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(ApiCollection.chanakyaGuru.createCrashCourse);
      console.log("Crash Course Data", data)
      setCourseData(data);
    } catch (error) {
      console.error('Error fetching crash course:', error);
    } finally {
      setLoading(false); 
    }
  };




  return (
    <div className="course-generate-screen">
      <div className="chanakya-learn-header">
        <img
          src={AppSvg.chanakyaLogo}
          alt="chanakya logo"
          style={{ width: 150, height: 50, marginLeft: 10, cursor: "pointer" }}
          onClick={onLogoPress}
        />
      </div>
      {!sidepanelActive && (
        <div className="course-tagline">
          <div className="course-tagline-main">Your course, Your way!</div>
          <div className="course-tagline-sub">Customize your course as you like. You can add, edit, delete modules as per your convenience</div>
          <button className="finalise-button" onClick={handleFinalize}>Finalise Course</button>
        </div>
      )}

      {loading ? (
        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100vh",
        }}>
          <img
            src={AppSvg.loading}
            alt="loading"
            style={{ width: "30px", height: "30px" }}
          />
        </div>
      ) : courseData && (
        <div className={`course-module-content-holder ${sidepanelActive ? 'with-sidepanel' : ''}`}>
          <div className={`course-module-content ${sidepanelActive ? 'with-sidepanel' : ''}`}>
            <span className="course-title">Course Overview</span>
            <h2 className="course-module-title">{courseData.course_name}</h2>

            <div className="submodule-container">
              <div className="crash-course-description">
                <p>{courseData.course_overview}</p>
                <p>{courseData.skill_analysis}</p>
              </div>
            </div>

            <div className="learn-section">
              <h3 className="learn-section-title">What you will learn!</h3>
            </div>
            <div className="crash-modules">
              {courseData.modules.map((module) => (
                <div key={module.id} className="faq-item">
                  <div className="faq-question">
                    <span>{module.title}</span>
                  </div>
                  <div className="faq-answer show">
                    <ul>
                      {module.content.map((item, index) => (
                        <li key={index} style={{ listStyleType: 'disc', marginLeft: '20px', fontSize: '16px' }}>
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>

          </div>
        </div>
      )}

      <SidePanel
        isOpen={showBot}
        title="Ask Chanakya"
        icon={LearnIcons.AskBot}
        onClose={() => {
          setShowBot(false)
          setSidepanelActive(false)
        }}
      >
        <ChatContainer
          usage="course-modify"
          sourcUrl={ApiCollection.chanakyaGuru.createCrashCourseChat}
          onClose={() => {
            setShowBot(false)
            setSidepanelActive(false)
          }}
          onReplace={fetchCrashCourse}
          referenceData={courseData?.course_name}
        />
      </SidePanel>

      {sidepanelActive === false && (
        <ChatBotButton
          showBot={showBot}
          onPress={() => {
            setShowBot(true);
            setSidepanelActive(true);
          }}
        />
      )}
    </div>
  );
};

export default MyCourse;
