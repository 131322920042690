import React, { useEffect, useRef } from 'react';
import Message from './Message';
import TypingIndicator from './TypingIndicator';


interface MessageListProps {
  messages: Array<{
    id: string;
    text: string;
    isBot: boolean;
    showReplace?: boolean;
  }>;
  isTyping: boolean;
  showReplaceButton?: boolean;
  onReplace?: () => void;
  usage: 'course-help' | 'course-modify';
}

const MessageList: React.FC<MessageListProps> = ({ 
  messages, 
  isTyping, 
  showReplaceButton, 
  onReplace 
}) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };




  useEffect(() => {
    scrollToBottom();
  }, [messages, isTyping]);

  return (
    <div className="message-list">
      {messages.map((message) => (
        <Message key={message.id} message={message} />
      ))}
      {showReplaceButton && (
        <div className="replace-button-container">
          <button 
            className="replace-button"
            onClick={onReplace}
          >
            <span className="replace-icon">⟳</span>
            <span className="replace-text">Confirm & Replace</span>
          </button>
        </div>
      )}
      {isTyping && <TypingIndicator />}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default MessageList;