import './styles.css';


interface ButtonProps {
    onClick?: () => void;
    style?: any
    className?: string;
    title: string | React.ReactNode;
    disabled?: boolean;
    icon?: React.ReactNode;
}

export default function Button(props: ButtonProps) {
    return (
        <button
            disabled={props.disabled || false}
            onClick={props.onClick}
            className={`app-button ${props.className || ''} ${props.disabled ? 'disabled' : ''}`}
            style={props.style}>
            {props.title}
        </button>
    )
}