import { useNavigate } from "react-router-dom";
import "./styles.css";
import { useEffect, useState } from "react";
import Profile from "./profile";
import DataControl from "./data";
import { useAxios } from "../../../hooks/useAxios";
import errorHandler from "../../../utils/common/errHandler";
import UserPersona from "./userPersona";
import { ApiCollection } from "../../../config/envConfig";

export default function SettingsScreen() {
  const getPath = window.location.pathname.split("/");
  const currentTab = getPath[getPath.length - 1];

  const navigate = useNavigate();
  const axios = useAxios();



  useEffect(() => {
    getUserProfile();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  


  const [selectedTab, setSelectedTab] = useState(currentTab);
  const [userProfile, setUserProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getUserProfile = async () => {
    // setIsLoading(true)
   await axios
      .get(ApiCollection.user.getUser)
      .then((res) => {
        setUserProfile(res.data);
        console.log(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.warn(err);
        errorHandler("User Info", err);
        setIsLoading(false);
      });
  };

  const tabs = [
    {
      id: 1,
      name: "Profile",
      link: "profile",
      component: <Profile user={userProfile} />,
    },
    { id: 2, name: "Data Control", link: "data", component: <DataControl /> },
    { id: 3, name: "User Persona", link: "persona", component: <UserPersona/> },
  ];

  const onTabClick = (tab: string) => {
    setSelectedTab(tab);
    navigate(`/settings/${tab}`);
  };

  return (
    <div className="settings-screen">
      <div className="side-bar">
        <h1>Settings</h1>
        <ul>
          {tabs.map((tab) => (
            <li
              className={currentTab === tab.link ? "active" : "inactive"}
              key={tab.id}
              onClick={() => onTabClick(tab.link)}
            >
              {tab.name}
            </li>
          ))}
        </ul>
      </div>
      <div className="settings-content">
        {!isLoading ? (
          (userProfile &&
            tabs.find((tab) => tab.link === selectedTab)?.component) || <></>
        ) : (
          <div className="loadingspinner">
            <div id="square1"></div>
            <div id="square2"></div>
            <div id="square3"></div>
            <div id="square4"></div>
            <div id="square5"></div>
          </div>
        )}
      </div>
    </div>
  );
}
